import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, defer, from, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { IValidator } from '@abstract/PreventUnloadValidator';
import { ITemplate } from '@flashy/email-editor';
import {
  EFlashyFolderResource,
  EFlashyStatus,
  IFlashyEntityReport,
  IUpgradeRequired,
} from '@flashy/store';
import { FlashyAutomation } from '@flashy/store/automation';
import { EFlashyCatalogType, FlashyCatalog } from '@flashy/store/catalog';
import { FlashyContact } from '@flashy/store/contact';
import {
  FlashyContactProperty,
  FlashyContactPropertyField,
} from '@flashy/store/contact-property';
import { FlashyDomain } from '@flashy/store/domain';
import {
  FlashyEmailCampaign,
  IValidateEmailCampaignData,
} from '@flashy/store/email-campaign';
import { FlashyFolder } from '@flashy/store/folder';
import { FlashyIntegration } from '@flashy/store/integration';
import {
  EFlashyPlanLevels,
  FlashyPaymentMethod,
  ProductTypeContainPlan,
  QuoteUsage,
  TFlasyCancelPlan,
} from '@flashy/store/plan';
import { FlashyPopup } from '@flashy/store/popup';
import { FlashyPreviewType } from '@flashy/store/preview';
import { FlashySegment } from '@flashy/store/segment';
import { FlashyShare } from '@flashy/store/share';
import { FlashySmsCampaign } from '@flashy/store/sms-campaign';
import { FlashyTemplate } from '@flashy/store/template';
import { FlashyWebhook } from '@flashy/store/webhook';
import { FlashyUiModalService, IFlashyModalConfig } from '@flashy/ui/modal';
import { FlashyUser } from '@flashy/users/domain';
import { IShowMessageInterface } from '@interceptors/show-message/show-message.interceptor';
import { IAttachToFolderData } from '@modals/attach-to-folder/attach-to-folder.component';
import { ICreateEntityData } from '@modals/create-entity/create-entity.component';
import { IEditEntityData } from '@modals/edit-entity/edit-entity.component';
import { ISendingProfile } from '@modals/settings/sending-profile/sending-profile.component';

@Injectable({
  providedIn: 'root',
})
export class ModalsService {
  constructor(private modalService: FlashyUiModalService) {}

  createPushNotification(): Observable<FlashyIntegration> {
    return from(
      import('@modals/settings/push-notification/push-notification.component')
    ).pipe(
      switchMap(({ PushNotificationComponent }) =>
        this.openModal<never, FlashyIntegration>(PushNotificationComponent)
      )
    );
  }

  addInstallIntegration(appKey: string): Observable<string> {
    return from(
      import('@modals/integrations/install/integration.component')
    ).pipe(
      switchMap(({ IntegrationComponent }) =>
        this.openModal<
          {
            appKey: string;
          },
          string
        >(IntegrationComponent, { data: { appKey } })
      )
    );
  }

  addImportIntegration(appKey: string): Observable<FlashyIntegration> {
    return defer(() => {
      if (appKey !== 'comax') {
        return from(
          import('@modals/integrations/import/import.component')
        ).pipe(map(({ ImportComponent }) => ImportComponent));
      } else {
        return from(
          import('@modals/integrations/cmax-import/import.component')
        ).pipe(map(({ CMAXImportComponent }) => CMAXImportComponent));
      }
    }).pipe(
      switchMap((component) =>
        this.openModal<{ appKey: string }, FlashyIntegration>(component, {
          data: { appKey },
        })
      )
    );
  }

  searchModal(data?: unknown): Observable<void> {
    return from(import('@modals/search/search.component')).pipe(
      switchMap(({ SearchComponent }) =>
        this.openModal<unknown, void>(SearchComponent, { data })
      )
    );
  }

  advancedDeliveryOptions(): Observable<void> {
    return from(
      import(
        '@modals/advanced-delivery-options/advanced-delivery-options.component'
      )
    ).pipe(
      // eslint-disable-next-line @typescript-eslint/no-shadow
      switchMap(({ AdvancedDeliveryOptionsComponent }) =>
        this.openModal<unknown, void>(AdvancedDeliveryOptionsComponent)
      )
    );
  }

  createEntity<T>(data: ICreateEntityData<T>): Observable<T> {
    return from(import('@modals/create-entity/create-entity.component')).pipe(
      switchMap(({ CreateEntityComponent }) =>
        this.openModal<ICreateEntityData<T>, T>(CreateEntityComponent, {
          data,
        })
      )
    );
  }

  editEntity<T>(data: IEditEntityData<T>): Observable<T> {
    return from(import('@modals/edit-entity/edit-entity.component')).pipe(
      switchMap(({ EditEntityComponent }) =>
        this.openModal<IEditEntityData<T>, T>(EditEntityComponent, { data })
      )
    );
  }

  createSendingProfile<T>(
    data: ISendingProfile<T>,
    width: string = '598px'
  ): Observable<T | undefined> {
    return from(
      import('@modals/settings/sending-profile/sending-profile.component')
    ).pipe(
      switchMap(({ SendingProfileComponent }) =>
        this.openModal<ISendingProfile<T>, T>(SendingProfileComponent, {
          data,
          width,
        })
      )
    );
  }

  attachToFolders(
    ids: number[],
    resource: EFlashyFolderResource
  ): Observable<FlashyFolder[]> {
    return from(
      import('@modals/attach-to-folder/attach-to-folder.component')
    ).pipe(
      switchMap(({ AttachToFolderComponent }) =>
        this.openModal<IAttachToFolderData, FlashyFolder[]>(
          AttachToFolderComponent,
          {
            data: { ids, resource },
          }
        )
      )
    );
  }

  share(
    data: FlashyTemplate | FlashyPopup | FlashyAutomation,
    type: FlashyShare['type'],
    share: FlashyShare
  ): Observable<FlashyShare> {
    return from(import('@modals/share-template/share-template.component')).pipe(
      switchMap(({ ShareTemplateComponent }) =>
        this.openModal<unknown, FlashyShare>(ShareTemplateComponent, {
          data: { ...data, type, share },
          disableClose: true,
        })
      )
    );
  }

  sendTest(
    id: number,
    type: FlashyPreviewType,
    contactId?: string
  ): Observable<string> {
    return from(import('@modals/send-test/send-test.component')).pipe(
      switchMap(({ SendTestComponent }) =>
        this.openModal<unknown, string>(SendTestComponent, {
          data: {
            id,
            type,
            contactId,
          },
        })
      )
    );
  }

  sendTestSMS(id: number): Observable<string> {
    return from(import('@modals/send-test-sms/send-test-sms.component')).pipe(
      switchMap(({ SendTestSmsComponent }) =>
        this.openModal<unknown, string>(SendTestSmsComponent, {
          data: { id },
        })
      )
    );
  }

  upgrade(
    level: EFlashyPlanLevels,
    source: string,
    plan?: ProductTypeContainPlan
  ): Observable<boolean> {
    return from(import('@modals/upgrade/upgrade.component')).pipe(
      switchMap(({ UpgradeComponent }) =>
        this.openModal<
          {
            level: EFlashyPlanLevels;
            source: string;
            plan?: ProductTypeContainPlan;
          },
          string | boolean
        >(UpgradeComponent, { data: { level, source, plan } })
      ),
      switchMap((event) => {
        if (event === 'upgrade') {
          return from(
            import(
              '@modals/show-upgrade-message/show-upgrade-message.component'
            )
          ).pipe(
            switchMap(({ ShowUpgradeMessageComponent }) =>
              this.openModal<unknown, boolean>(ShowUpgradeMessageComponent)
            ),
            map(() => true)
          );
        } else {
          return of(Boolean(event));
        }
      })
    );
  }

  showMessage(data: IShowMessageInterface): Observable<never> {
    return from(import('@modals/show-message/show-message.component')).pipe(
      switchMap(({ ShowMessageComponent }) =>
        this.openModal<IShowMessageInterface, never>(ShowMessageComponent, {
          data,
        })
      )
    );
  }

  scheduleResendCampaign(
    form: FlashyEmailCampaign['options'] & {
      currentId: FlashyEmailCampaign['id'];
    }
  ): Observable<Partial<FlashyEmailCampaign['options']>> {
    return from(
      import('@modals/schedule-resend/schedule-resend.component')
    ).pipe(
      switchMap(({ ScheduleResendComponent }) =>
        this.openModal<unknown, Partial<FlashyEmailCampaign['options']>>(
          ScheduleResendComponent,
          { data: form }
        )
      )
    );
  }

  scheduleCampaign<
    T = ITemplate,
    R = FlashyEmailCampaign<T> | FlashySmsCampaign,
  >(campaign: R, issues: IValidateEmailCampaignData): Observable<R> {
    return from(import('@modals/schedule/schedule.component')).pipe(
      switchMap(({ ScheduleComponent }) =>
        this.openModal<unknown, R>(ScheduleComponent, {
          data: { campaign, issues },
        })
      )
    );
  }

  createContactProperty(
    property?: FlashyContactPropertyField & { group: string }
  ): Observable<FlashyContactProperty> {
    return from(
      import('@modals/settings/contact-property/contact-property.component')
    ).pipe(
      switchMap(({ ContactPropertyComponent }) =>
        this.openModal<unknown, FlashyContactProperty>(
          ContactPropertyComponent,
          { data: property }
        )
      )
    );
  }

  verifyAccount(): Observable<boolean> {
    return from(import('@modals/verify-account/verify-account.component')).pipe(
      switchMap(({ VerifyAccountComponent }) =>
        this.openModal<unknown, boolean>(VerifyAccountComponent)
      )
    );
  }

  verifyDomain(entity: FlashyDomain): Observable<boolean> {
    return from(import('@modals/verify-domain/verify-domain.component')).pipe(
      switchMap(({ VerifyDomainComponent }) =>
        this.openModal<FlashyDomain, boolean>(VerifyDomainComponent, {
          data: entity,
        })
      )
    );
  }

  createWebhook(webhook?: FlashyWebhook): Observable<FlashyWebhook[]> {
    return from(
      import('@modals/settings/send-webhook/send-webhook.component')
    ).pipe(
      switchMap(({ SendWebhookComponent }) =>
        this.openModal<FlashyWebhook, FlashyWebhook[]>(SendWebhookComponent, {
          data: webhook,
        })
      )
    );
  }

  createApi(): Observable<string> {
    return from(import('@modals/create-api/create-api.component')).pipe(
      switchMap(({ CreateApiComponent }) =>
        this.openModal<never, string>(CreateApiComponent)
      )
    );
  }

  planListing(selectedIndex = 0): Observable<EFlashyPlanLevels> {
    return from(
      import('@modals/settings/upgrade-plan/upgrade-plan.component')
    ).pipe(
      switchMap(({ UpgradePlanComponent }) =>
        this.openModal<unknown, EFlashyPlanLevels>(UpgradePlanComponent, {
          data: selectedIndex,
        })
      )
    );
  }

  editColumns(columns: string[]): Observable<string[]> {
    return from(import('@modals/edit-columns/edit-columns.component')).pipe(
      switchMap(({ EditColumnsComponent }) =>
        this.openModal<unknown, string[]>(EditColumnsComponent, {
          data: columns,
        })
      )
    );
  }

  editContact(contact: FlashyContact): Observable<FlashyContact> {
    return from(import('@modals/edit-contact/edit-contact.component')).pipe(
      switchMap(({ EditContactComponent }) =>
        this.openModal<FlashyContact, FlashyContact>(EditContactComponent, {
          data: contact,
        })
      )
    );
  }

  editContactSubscriptions(contact: FlashyContact): Observable<FlashyContact> {
    return from(
      import(
        '@modals/edit-contact-subscriptions/edit-contact-subscriptions.component'
      )
    ).pipe(
      switchMap(({ EditContactSubscriptionsComponent }) =>
        this.openModal<FlashyContact, FlashyContact>(
          EditContactSubscriptionsComponent,
          {
            data: contact,
          }
        )
      )
    );
  }

  inviteUser(entity?: FlashyUser): Observable<FlashyUser['roles']> {
    return from(
      import('@modals/settings/invite-user/invite-user.component')
    ).pipe(
      switchMap(({ InviteUserComponent }) =>
        this.openModal<FlashyUser, FlashyUser['roles']>(InviteUserComponent, {
          data: entity,
        })
      )
    );
  }

  openPreview(data: {
    type: FlashyPreviewType;
    id: number;
    device: 'mobile' | 'desktop' | 'browser';
    landing?: boolean;
  }): Observable<boolean> {
    return from(import('@modals/preview/preview.component')).pipe(
      switchMap(({ PreviewComponent }) =>
        this.openModal<unknown, boolean>(PreviewComponent, {
          data,
          disableAnimation: true,
        })
      )
    );
  }

  createCatalog(data: EFlashyCatalogType): Observable<FlashyCatalog> {
    return from(
      import('@modals/settings/create-catalog/create-catalog.component')
    ).pipe(
      switchMap(({ CreateCatalogComponent }) =>
        this.openModal<EFlashyCatalogType, FlashyCatalog>(
          CreateCatalogComponent,
          { data }
        )
      )
    );
  }

  createSegment(
    segment?: Partial<FlashySegment>
  ): Observable<FlashySegment | null | 'deleted'> {
    return from(import('@modals/create-segment/create-segment.component')).pipe(
      switchMap(({ CreateSegmentComponent }) =>
        this.openModal<unknown, FlashySegment | null | 'deleted'>(
          CreateSegmentComponent,
          {
            data: segment,
            disableClose: true,
          }
        )
      )
    );
  }

  export(contactsNumber?: number): Observable<string> {
    return from(import('@modals/export/export.component')).pipe(
      switchMap(({ ExportComponent }) =>
        this.openModal<unknown, string>(ExportComponent, {
          data: contactsNumber,
        })
      )
    );
  }

  deleteAllContacts(contactsNumber?: number): Observable<string> {
    return from(
      import('@modals/delete-all-contacts/delete-all-contacts.component')
    ).pipe(
      switchMap(({ DeleteAllContactsComponent }) =>
        this.openModal<unknown, string>(DeleteAllContactsComponent, {
          data: contactsNumber,
        })
      )
    );
  }

  addContact(width: string = '598px'): Observable<FlashyContact> {
    return from(import('@modals/add-contact/add-contact.component')).pipe(
      switchMap(({ AddContactComponent }) =>
        this.openModal<unknown, FlashyContact>(AddContactComponent, { width })
      )
    );
  }

  addNewCard(): Observable<FlashyPaymentMethod> {
    return from(import('@modals/add-new-cart/add-new-cart.component')).pipe(
      switchMap(({ AddNewCartComponent }) =>
        this.openModal<unknown, FlashyPaymentMethod>(AddNewCartComponent)
      )
    );
  }

  purchaseVirtualNumber(): Observable<never> {
    return from(
      import(
        '@modals/purchase-virtual-number/purchase-virtual-number.component'
      )
    ).pipe(
      switchMap(({ PurchaseVirtualNumberComponent }) =>
        this.openModal<unknown, never>(PurchaseVirtualNumberComponent)
      )
    );
  }

  addBalance(): Observable<void> {
    return from(import('@modals/add-balance/add-balance.component')).pipe(
      switchMap(({ AddBalanceComponent }) =>
        this.openModal<unknown, void>(AddBalanceComponent)
      )
    );
  }

  validator<T>(data: IValidator[]): Observable<T> {
    return from(import('@modals/validator/validator.component')).pipe(
      switchMap(({ ValidatorComponent }) =>
        this.openModal<IValidator[], T>(ValidatorComponent, { data })
      )
    );
  }

  upgradeMessage(message: IUpgradeRequired): Observable<number> {
    return from(
      import('@modals/upgrade-message/upgrade-message.component')
    ).pipe(
      switchMap(({ UpgradeMessageComponent }) =>
        this.openModal<IUpgradeRequired, number>(UpgradeMessageComponent, {
          data: message,
        })
      )
    );
  }

  reAuth(): Observable<boolean> {
    return from(import('@modals/re-auth/re-auth.component')).pipe(
      switchMap(({ ReAuthComponent }) =>
        this.openModal<unknown, boolean>(ReAuthComponent, {
          disableClose: true,
        })
      )
    );
  }

  support(): Observable<never> {
    return from(import('@modals/support/support.component')).pipe(
      switchMap(({ SupportComponent }) =>
        this.openModal<unknown, never>(SupportComponent)
      )
    );
  }

  bookAMeeting(): Observable<never> {
    return from(import('@modals/book-ameeting/book-ameeting.component')).pipe(
      switchMap(({ BookAMeetingComponent }) =>
        this.openModal<unknown, never>(BookAMeetingComponent)
      )
    );
  }

  showUsage(graph: QuoteUsage['graph']): Observable<never> {
    return from(
      import('@modals/usage-graph/usage-graph/usage-graph.component')
    ).pipe(
      switchMap(({ UsageGraphComponent }) =>
        this.openModal<unknown, never>(UsageGraphComponent, { data: graph })
      )
    );
  }

  cancelPlan(): Observable<TFlasyCancelPlan> {
    return from(import('@modals/cancel-plan/cancel-plan.component')).pipe(
      switchMap(({ CancelPlanComponent }) =>
        this.openModal<unknown, TFlasyCancelPlan>(CancelPlanComponent)
      )
    );
  }

  showImportReasons(reasons: IFlashyEntityReport['reasons']): Observable<void> {
    return from(
      import(
        '@modals/show-import-history-errors/show-import-history-errors.component'
      )
    ).pipe(
      switchMap(({ ShowImportHistoryErrorsComponent }) =>
        this.openModal<unknown, void>(ShowImportHistoryErrorsComponent, {
          data: reasons,
        })
      )
    );
  }

  changeStatus(status: EFlashyStatus): Observable<boolean> {
    return from(import('@modals/state-switcher/state-switcher.component')).pipe(
      switchMap(({ StateSwitcherComponent }) =>
        this.openModal<EFlashyStatus, boolean>(StateSwitcherComponent, {
          data: status,
        })
      )
    );
  }

  reasonModal(): Observable<string> {
    return from(import('@modals/state-reason/state-reason.component')).pipe(
      switchMap(({ StateReasonComponent }) =>
        this.openModal<unknown, string>(StateReasonComponent, {
          disableClose: true,
        })
      )
    );
  }

  private openModal<T, R>(
    cmp: ComponentType<unknown>,
    config?: IFlashyModalConfig<T>
  ): Observable<R> {
    return this.modalService
      .openModal<T, R>(cmp, config)
      .afterClosed()
      .pipe(
        switchMap((res) => {
          //   If res is string, it means that the user clicked on the button,
          //   and we need to return the data
          if (typeof res === 'string' || res) {
            return of(res);
          } else {
            return EMPTY;
          }
        }),
        take(1) //  Unsubscribe after the first value
      );
  }
}
